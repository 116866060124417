<template>
  <div class="section py-5">
    <div class="container-my mx-auto py-4">
      <div class="row d-flex justify-content-center align-middle">
        <div class="col-12">
          <h2 class="w700 text-center text-primary">Pick the One You Like</h2>
          <h5 class="w400 text-center text-dark col-12 col-md-10 col-lg-8 mx-auto">
            We have various colors that you can choose from.
            <br />So that everyone can find a color that suits them.
          </h5>
          <div class="row d-flex justify-content-center align-middle">
            <div class="col-12 col-md-6 mt-5 px-4">
              <img class="w-100" src="../assets/img/paperwalletemptyA.svg" />
            </div>
            <div class="col-12 col-md-6 mt-5 px-4">
              <img class="w-100" src="../assets/img/paperwalletemptyB.svg" />
            </div>
            <div class="col-12 col-md-6 mt-5 px-4">
              <img class="w-100" src="../assets/img/paperwalletemptyC.svg" />
            </div>
            <div class="col-12 col-md-6 mt-5 px-4">
              <img class="w-100" src="../assets/img/paperwalletemptyD.svg" />
            </div>
            <div class="col-12 col-md-6 mt-5 px-4">
              <img class="w-100" src="../assets/img/paperwalletemptyE.svg" />
            </div>
            <div class="col-12 col-md-6 mt-5 px-4">
              <img class="w-100" src="../assets/img/paperwalletemptyF.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Questions"
});
</script>